.scrollingTextContainer {
  position: relative;
  width: 100%;
  height: 25px; 
  overflow: hidden;
  /* background-color: red;  */
  background-color: rgb(20, 79, 20);
}

.scrollingText {
  display: flex;
  white-space: nowrap;
  animation: scrollText 7.5s linear infinite;
}

.textContent {
  flex-shrink: 0;
  font-size: 18px;
  color: white;
  white-space: pre-wrap;
}

@keyframes scrollText {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(100%);
  }
}