.poll-container {
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    align-items: center; 
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .poll-item {
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 45%; 
    box-sizing: border-box;
  }
  
  .poll-image {
    max-width: 150%; 
    height: auto;
    margin-bottom: 10px; 
  }

  .poll2-image {
    max-width: 140%; 
    height: auto;
    margin-bottom: 10px; 
  }
  
  button {
    margin-bottom: 10px; 
    background-color: white;
  }

  button.active {
    font-weight: normal; 
    margin-bottom: 10px; 
    background-color: white;
  }
  
  .poll-count {
    font-weight: 500;
    font-size: xx-large;
  }