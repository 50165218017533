/* .newsPage {
    display: flex;
    padding-top: 2%;
    color: rgb(122, 94, 57);
    position: relative; 
  }
  
  .leftContainer {
    width: 22%;
    position: absolute;
    left: 8%; 
    box-sizing: border-box;
  }
  
  .middleContainer {
    width: 35%;
    position: absolute;
    left: 35%; 
    box-sizing: border-box;
  }

  .midBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: left;
  }
  
  .midBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .midBox p {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .rightContainer {
    width: 15%;
    position: absolute;
    left: 75%;
    box-sizing: border-box;
  }
  
  .locksBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: center;
  }
  
  .locksBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .locksBox p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: larger;
  }

  .pollBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: center;
  }
  
  .pollBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .pollBox p {
    margin-top: 0;
    margin-bottom: 0;
  } */

  .newsPage {
    display: flex;
    padding-top: 2%;
    color: rgb(122, 94, 57);
    position: relative; 
  }
  
  .leftContainer {
    width: 22%;
    position: absolute;
    left: 8%; 
    box-sizing: border-box;
  }
  
  .middleContainer {
    width: 35%;
    position: absolute;
    left: 35%; 
    box-sizing: border-box;
  }

  .midBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: left;
  }
  
  .midBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .midBox p {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .rightContainer {
    width: 15%;
    position: absolute;
    left: 75%;
    box-sizing: border-box;
  }
  
  .locksBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: center;
  }
  
  .locksBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .locksBox p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: larger;
  }

  .pollBox {
    width: 100%; 
    background-color: white; 
    border-radius: 10px; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(16, 16, 16, 0.1); 
    border: 1.5px solid rgb(122, 94, 57);
    text-align: center;
  }
  
  .pollBox h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  
  .pollBox p {
    margin-top: 0;
    margin-bottom: 0;
  }

/* Mobile view adjustments */
@media only screen and (max-width: 600px) {
  .newsPage {
    display: block;
    padding-top: 2%;
  }

  .leftContainer, .middleContainer, .rightContainer {
    position: static;
    width: 100vw;
    box-sizing: border-box;
    padding: 0;
  }

  .middleContainer {
    order: 1;
  }

  .rightContainer {
    order: 2;
  }

  .leftContainer {
    order: 3;
  }

  .midBox, .locksBox, .pollBox {
    width: 100vw;
    margin-bottom: 20px;
  }
}
