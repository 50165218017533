/* Chat.css */
html, body {
    height: 100%;
    margin: 0;
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 50vh;
  }
  
  .chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .chat-message {
    margin-bottom: 10px;
  }
  
  .chat-form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
  }
  
  .chat-form input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .chat-form button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chat-form button:hover {
    background-color: #0056b3;
  }
  
